



















import { updateSectionTestGroupApi } from '@/api/project'
import { EntityType, ProjectFormType, UpdateSectionTestGroupDto } from '@/api/project/model'
import { showError } from '@/utils/common'
import { computed, ComputedRef, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { updateOrCreateConversationMessageApi, updateOrCreateOnboardingPollApi,resetNumbersApi } from '@/api/project'
import { cloneDeep } from 'lodash'
export default defineComponent({
    components: {
    },
    props: {

    },
    setup(props, { root, emit }) {
       
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const state = reactive({
            show: false,
            testGroupId: '',
            loading: false,
            location: 'tail',
            isCollapse: false,
            message_entity: {} as any,
            testGroupInfo: {} as any,
            itemObj: {} as any,
            formType: ProjectFormType.Basic,
            onOpen: (item: any, formType: ProjectFormType) => {
                state.itemObj = cloneDeep(item)
                state.formType = formType
                state.show = true
            },
            onSave: async (formType?: number) => {
                try {
                    if (formType == 1) {
                        const data = await resetNumbersApi({uuid: state.itemObj.project_uuid})
                        emit('success')
                    } else {
                        if (state.formType == ProjectFormType.Basic) {
                            const data = await updateOrCreateOnboardingPollApi(state.itemObj)
                            emit('success', state.formType)
                        } else {
                            const data = await updateOrCreateConversationMessageApi(state.itemObj)
                            emit('success', state.formType)
                        }
                    }

                    state.show = false
                } catch (error) {
                    showError(error)
                }

            },
          filterInput: (value: string) => {
                 // 允许字母、数字和小数点，不允许空格和其他符号
            const filteredValue = value.replace(/[^a-zA-Z0-9.]/g, '').replace(/\s/g, '')
            state.itemObj.message_entity.number = filteredValue
            }
        })

        return {
            ProjectFormType,
            EntityType,
            ...toRefs(state)
        }
    }
})
